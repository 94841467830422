import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import Grow from '@mui/material/Grow';
import { Paper, Typography } from '@mui/material';
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider';
import AnchorIcon from '@mui/icons-material/Anchor';
import SailingIcon from '@mui/icons-material/Sailing';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import Resizer from "react-image-file-resizer";

import {useState, useEffect} from 'react'

import CameraAltIcon from '@mui/icons-material/CameraAlt';
  
import { useNavigate, useLocation } from 'react-router-dom'
import PostFunction from '../API/postFunction';
import GetFunction from '../API/getFunction';

// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'

       
export default function FeedNewPost() {

  // PARAMETRAGE
  // Distance maximale des spots proches suggérés
  const MaxDistance = 10

  // Compression des images
  const MaxWidth = 1500    // Largeur max
  const MaxHeight = 1500    // Hauteur max
  const Quality = 90      // Qualité (0-100)
  const MinWidth = 1500    // Largeur min
  const MinHeight = 1500    // Hauteur min

  const selectProfile = state => state.myProfile
  const profil = useSelector(selectProfile)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectMarkersArray = state => state.markersArray
  const markersArray = useSelector(selectMarkersArray)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // On récupère le spot passés en state du lien
  const {state} = useLocation();
  const { spot } = state

  let markerImgZoom
  
  if (spot.spotType === "Port") {
    markerImgZoom = 15
  } else if (spot.spotType === "Mouillage") {
    markerImgZoom = 12
  }
  
  const [newPostState, setNewPostState] = useState({
    text : "",
    newPicFile : null,
    isFetching : false,
    isResizingPic : false,
    alertObject : null,
  })

  const [displaySpotList, setDisplaySpotList] = useState(false)
  const [publishNearbySpots, setPublishNearbySpots] = useState(true)

  let cloneMarkersArray = [...markersArray] // On clone pour ne plus avoir de lien avec le state et éviter les problème d'immutability
  let nearbyMarkers = cloneMarkersArray.filter(marker => {
    let start = {
      latitude : marker.spotLatitude,
      longitude : marker.spotLongitude
    }
    let end = {
      latitude : spot.spotLatitude,
      longitude : spot.spotLongitude
    }
    let distance = calculateDistance(start, end)       
    return((distance < MaxDistance)) 
  }).sort((a,b)=> {
    let start = {
      latitude : spot.spotLatitude,
      longitude : spot.spotLongitude
    }
    let enda = {
      latitude : a.spotLatitude,
      longitude : a.spotLongitude
    }
    let endb = {
      latitude : b.spotLatitude,
      longitude : b.spotLongitude
    }
    let distancea = calculateDistance(start, enda)       
    let distanceb = calculateDistance(start, endb)   
    if (distancea < distanceb) { return -1}    
    if (distancea > distanceb) { return 1}    
    return 0    
  })
  nearbyMarkers.forEach((item,index) => {
    let start = {
      latitude : spot.spotLatitude,
      longitude : spot.spotLongitude
    }
    let end = {
      latitude : item.spotLatitude,
      longitude : item.spotLongitude
    }
    nearbyMarkers[index] = {...item, distance:calculateDistance(start,end)}
  })


  // Identification des spots proches et mise dans le state

  function calculateDistance(start,end) {
    let lat1 = start.latitude
    let lon1 = start.longitude
    let lat2 = end.latitude
    let lon2 = end.longitude
    let R = 6371  // km
    let dLat = 2*Math.PI*R*(lat2 - lat1)/360 
    let dLon = 2 * Math.PI * R * (lon2 - lon1) / 360 * Math.cos((lat1+lat2)/2/360*2*Math.PI)
    let distance = Math.sqrt(Math.pow(dLat,2)+Math.pow(dLon,2))
    return distance 
  }
  
  useEffect(() => {
    console.log('FeedNewPost.js -> useEffect : chargemment dans le state de la liste des spots proches')
    if (nearbyMarkers.length >0) {
      setNewPostState(prevState => ({
        ...prevState,
        nearbyMarkers : nearbyMarkers,
        selectedNearbyMarkers : nearbyMarkers
      }))
    }
   },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  const handleSwitchChange = (event) => {
    if (event.target.checked) {
      let newSelectedMarker = newPostState.nearbyMarkers.find((marker) => (marker.pk.toString() === event.target.name.toString()))
      let newSelectedNearbyMarkers = newPostState.selectedNearbyMarkers
      newSelectedNearbyMarkers.push(newSelectedMarker)
      setNewPostState(prevState => ({
        ...prevState,
        selectedNearbyMarkers : newSelectedNearbyMarkers
      }))
    } else {
      let newSelectedNearbyMarkers = newPostState.selectedNearbyMarkers.filter(marker => marker.pk.toString() !== event.target.name.toString())
      setNewPostState(prevState => ({
        ...prevState,
        selectedNearbyMarkers : newSelectedNearbyMarkers
      }))
    }
  };

  function NearbySpotsChoice() {
    if (newPostState.nearbyMarkers && displaySpotList) {
      return (
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            {newPostState.nearbyMarkers.map((marker) => 
              (marker.pk !== spot.pk?
                <FormControlLabel
                  key={marker.pk}
                  control={
                    <Switch 
                      checked={(newPostState.selectedNearbyMarkers.findIndex(spot => (spot.pk === marker.pk)) !== -1)}
                      onChange={handleSwitchChange}
                      name={marker.pk.toString()}
                      />
                  }
                  label={marker.spotName.concat(" (",Number.parseFloat(marker.distance).toFixed(1)," km)")}
                />
              :
              null
              )
            )}
          </FormGroup>
        </FormControl>
      );
    }
  }

  const onPicChange = (event) => {
    if (event.target.files.length > 0) { // Pour éviter le cas où l'utilisateur clique sur "Annuler" dans la fenêtre
      setNewPostState(prevState => ({
        ...prevState,
        isResizingPic : true
      }))
      // Lancement de la compression de l'image de profil
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          MaxWidth,    // Largeur max
          MaxHeight,    // Hauteur max
          "JPEG",  // Format de sortie
          Quality,      // Qualité (0-100)
          0,       // Rotation
          (uri) => {        // Caalback function (lancée à la fin du traitelent)
            console.log('FeedNewPost -> Image compressée')
            setNewPostState(prevState => ({
              ...prevState,
              isResizingPic : false,
              newPicFile : uri
            }))
          },
          "file",   // Type de sortie
          MinWidth,       // Largeur min
          MinHeight        // Hauteur min
        );
      } catch (err) {
        console.log('FeedNewPost -> Echec compression image')
        let errorMessage = "Votre image n'a pas pu être traitée."
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    }
  };

  const onPicDelete = () => {
    setNewPostState(prevState => ({
      ...prevState,
      newPicFile:null
    }))
  };

  const PostPicture = () => {
    if (newPostState.newPicFile) {
      return (
        <Box
          component="img"
          sx={{
            display: 'inline-flex',
            maxHeight: { xs: "10vh", md: "10vh" },
            maxWidth: { xs: "10vw", md: "10vw" },
          }}
          src={URL.createObjectURL(newPostState.newPicFile)}
        />
      )
    } else return null
  };

  const PicAddButton = () => {
    if (!newPostState.newPicFile) {
      return (
        <LoadingButton color="primary" aria-label="add picture" component="label"
            type="submit"
            variant="contained"
            loading={newPostState.isResizingPic}
            >
          <CameraAltIcon />
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );
    } else return null
  }

  const PicChangeButton = () => {
    if (newPostState.newPicFile) {
      return (
        <LoadingButton color="primary" aria-label="change picture" component="label"
            type="submit"
            variant="contained"
            loading={newPostState.isResizingPic}
            >
          <CameraAltIcon />
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );
    } else return null
  }

  const PicDeleteButton = () => {
    if (newPostState.newPicFile) {
      return (
        <Button color="error" aria-label="remove picture" component="label"
          type="submit"
          variant="contained"
          onClick={onPicDelete}
        >
          <CancelIcon />
        </Button>
      );
    }
  }
    
  function handleSubmitPost() {
    SendNewPost()
  };

  function handleSubmitCancel() {
    navigate(-1)
};

  function SendNewPost() {
    console.log('FeedNewPost.js -> Lancement API sendPost')
    setNewPostState(prevState => ({...prevState, isFetching:true}))
    
    const newPostFormData = new FormData();
    if (newPostState.newPicFile) {
    newPostFormData.append("postPicurl", newPostState.newPicFile,newPostState.newPicFile.name)
    } else {
      newPostFormData.append("postPicurl",new File([], ''))
    }
    newPostFormData.append("postAuthor", profil.pk)
    newPostFormData.append("postText", newPostState.text)

    newPostFormData.append("postSpot", 1)
    newPostFormData.append("postType", "Post")

    // Une request d'un serializer ne reussit pas à lire un array.
    // On va donc plutôt lui envoyer une serie de champs avec des keys "postSpotX"
    newPostState.selectedNearbyMarkers.forEach((spot,index) => {
      newPostFormData.append("postSpots" + index, spot.pk)
    })

    console.log("newPostFormData :")
    console.log(Object.fromEntries(newPostFormData))

    PostFunction({fetchTarget : 'sendPostForm', fetchArgument : newPostFormData, token : token})
    .then(response => {
      if (response.fetchStatus === 'Ok')  {
        console.log('FeedNewPost.js -> Envoi OK')
        setNewPostState(prevState => ({...prevState, isFetching:false}))

        // On récupère le UserProfile pour mettre à jour les points et le statut
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('FeedNewPost.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('FeedNewPost.js -> Réception du profil à jour en échec')
          }
        })
        navigate(-1)
      } else {
        console.log('Feed.js -> Envoi en échec')
        setNewPostState(prevState => ({...prevState,
          isFetching:false,
          alertObject:<Alert severity="error">Erreur lors de l'envoi de la publication</Alert>
        }))
        let errorMessage = "Erreur lors de l'envoi de la publication, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function handleChange(event) {
    setNewPostState(prevState => ({...prevState,[event.target.id]:event.target.value}))
  };

  function handlePublishNearbySpotsSwitchChange() {
    if (!publishNearbySpots) {
      setNewPostState(prevState => ({
        ...prevState,
        selectedNearbyMarkers : nearbyMarkers
      }))
      setPublishNearbySpots(true)
    } else {
      setNewPostState(prevState => ({
        ...prevState,
        selectedNearbyMarkers : nearbyMarkers.slice(0,1)
      }))
      setPublishNearbySpots(false)
    }
    setPublishNearbySpots(!publishNearbySpots)
  }

  const authorGlobalBadge = () => {
    if (profil.userGlobalBadge) {
    return (
      <Avatar
      src={profil.userGlobalBadge}  
      sx={{ width: 20, height: 20,marginRight : 1 }}
    />
      )
    } else {
    return null;
    }
  };

  return (
    <Grow in={true}>
      <Box sx={{display : "flex", flexDirection : "column", alignItems : "center", padding : 1}}>
        <Paper sx={{display : "flex", flexDirection : "column", borderRadius : 10, width : {xs : "90%", sm : "80%", md : "70%"}, backgroundColor : "white", padding : 1}} elevation={20}>
          <Typography variant="h6" color={"primary"} textAlign="center">
            Créer une publication
          </Typography>
          <Divider variant="middle" />
          <Box sx={{display:"flex", flexDirection:"row", alignItems : "center", marginBottom : 1, marginTop : 1}}>
            <Avatar src={profil.userAvatarurl} sx={{ width: 30, height: 30, marginRight : 1 }} />
            <Typography variant="subtitle2" color="primary" sx={{ marginRight : 1}}>
              {profil.userNickname}
            </Typography>
            {authorGlobalBadge()}
            <PlayArrowIcon/>
            {spot.spotType === "Port" ?
              <Chip sx={{m : 0.5}} icon={<SailingIcon fontSize="small"/>}  variant='outlined'  color="primary" clickable={false} label={spot.spotName}/>
              :
              (spot.spotType === "Mouillage" ?
                <Chip sx={{m : 0.5}} icon={<AnchorIcon fontSize="small"/>} variant='outlined' color="primary" clickable={false} label={spot.spotName}/>
                :
                null
              )
            }
          </Box>

          <TextField
            fullWidth
            multiline
            label="Que voulez-vous dire ?"
            rows={5}
            id="text"
            onChange={(event) => handleChange(event)}
            value={newPostState.text}
          />
          <Box sx={{display : "flex", flexDirection : "row"}}>
            {PostPicture()}
            <Box sx={{display:"flex", flexDirection:"column"}}>
              {PicAddButton()}
              {PicChangeButton()}
              {PicDeleteButton()}
            </Box>
          </Box>
          {newPostState.alertObject}
          <Box sx={{display : "flex", flexDirection : "column", alignItems : "flex-start", width : "100%", marginTop : 5}}>
            {(displaySpotList ?
              NearbySpotsChoice()
              :
              <Box sx={{display : "flex"}}>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={publishNearbySpots}
                      onChange={handlePublishNearbySpotsSwitchChange}
                    />
                  }
                  label={(publishNearbySpots ? "Publier aussi dans les spots proches (10km)" : "Publier dans ce spot uniquement")}
                />
                <Typography variant="subtitle2" color="primary" sx={{ marginRight : 1}}
                  display={(publishNearbySpots ? "flex" : "none")}
                  onClick={() => {setDisplaySpotList(true)}}
                >
                  Voir la liste
                </Typography>
              </Box>
            )}
            <Box sx={{display:"flex", flexDirection:"row", width : "100%", justifyContent: 'space-evenly'}}>
              <Button
                color="error"
                variant="contained"
                sx={{ mt: 3, ml: 2, mr:2 }}
                onClick={handleSubmitCancel}
              >
                <CancelIcon/>
              </Button>
              <LoadingButton
                variant="contained"
                color="success"
                sx={{ mt: 3, ml: 2, mr:2 }}
                onClick={handleSubmitPost}
                disabled={((!newPostState.text)&&(!newPostState.newPicFile))}
                loading={newPostState.isFetching}
              >
                <SendIcon />
              </LoadingButton>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Grow>
  );
}
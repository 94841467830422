import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

export default function TutorialIntro1() {

  const navigate = useNavigate()

  function PreviousButton() {
    return (
      <Button
        onClick={() => navigate ("/TutorialStart", {replace : true})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateBeforeIcon />
      </Button>
    );
  }

  function NextButton() {
    return (
      <Button
        onClick={() => navigate ("/TutorialIntro2", {replace : true})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateNextIcon />
      </Button>
    );
  }

  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
          <Typography variant = "h6" color={"primary"} paragraph={true}>
            Au port, discutez avec vos voisins, sans devoir arpenter les pontons !
          </Typography>
          <Typography color={"primary"} paragraph={true}>
            - J'ai besoin du code des sanitaires !
          </Typography>
          <Typography color={"primary"} paragraph={true}>
            - Qui pourrait me prêter une perceuse ?
          </Typography>
          <Typography color={"primary"} paragraph={true}>
            - Vous savez où je peux trouver un ship ouvert ?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {PreviousButton()}
        {NextButton()}
      </DialogActions>
    </Dialog>
  );
}
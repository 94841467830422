import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

export default function TutorialStart() {

  const navigate = useNavigate()

  function NextButton() {
    return (
      <Button
        onClick={() => navigate ("/TutorialIntro1", {replace : true})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateNextIcon />
      </Button>
    );
  }

  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
          <Typography variant = "h6" color={"primary"} paragraph={true}>
            Bienvenue sur NauticTalk - ou NauTicToc, ou même Nautiktok si vous préférez !
          </Typography>
          <Typography color={"primary"} paragraph={true}>
            L'appli qui vous permet de contacter facileement les autres marins, où qu'ils soient !
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {NextButton()}
      </DialogActions>
    </Dialog>
  );
}

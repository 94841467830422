import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import {useState} from 'react'
import { Typography } from '@mui/material';

export default function TutorialMap3(props) {

  const [checkboxState, setCheckboxState] = useState(true);

  function PreviousButton() {
    return (
      <Button
        onClick={() => props.goToTutorialPageFunction({page : "Map2"})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateBeforeIcon />
      </Button>
    );
  }

  function EndButton() {
    return (
      <Button
        onClick={() => {
          if (checkboxState) {
            localStorage.setItem('NAUTILUS_DO_NOT_SHOW_TUTORIAL', JSON.stringify({doNotShowTutorial : true}));
          } else {
            localStorage.removeItem('NAUTILUS_DO_NOT_SHOW_TUTORIAL')
          }
          props.goToTutorialPageFunction({page : "Close"})
        }}
        variant="contained"
        color="success"
        sx={{ mt: 3, mb: 2 }}
      >
        <CheckIcon />
      </Button>
    );
  }

  function CheckBoxElement() {
    return (
      <FormControlLabel
        control={<Checkbox checked={checkboxState} onChange={handleChangeCheckbox} color="primary" id="checkbox"/>}
        label="Ne plus me montrer ce tuto au démarrage"
      />
    );
  }

  const handleChangeCheckbox = (event) => {
    setCheckboxState(event.target.checked)
  };

  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
          <Typography variant = "h6" color={"primary"} paragraph={true}>
            Faites vivre notre communauté et soyez-en fier !
          </Typography>
          <Typography color={"primary"} paragraph={true}>
            Quand vous créez une publication, quand vous participez à une discussion, vous gagnez de points.
          </Typography>
          <Typography color={"primary"}  paragraph={true}>
            En cumulant les points, vous gagnez des badges, visibles sur la carte et dans les publications !
          </Typography>
        </DialogContentText>
        {CheckBoxElement()}
      </DialogContent>
      <DialogActions>
        {PreviousButton()}
        {EndButton()}
      </DialogActions>
    </Dialog>
  );
}
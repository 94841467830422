import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

export default function TutorialMap1(props) {
  const navigate = useNavigate()

  function PreviousButton() {
    return (
      <Button
        onClick={() => navigate ("/TutorialIntro3", {replace : true})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateBeforeIcon />
      </Button>
    );
  }

  function NextButton() {
    return (
      <Button
        onClick={() => props.goToTutorialPageFunction({page : "Map2"})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateNextIcon />
      </Button>
    );
  }

  return(
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
          <Typography variant = "h6" color={"primary"} paragraph={true}>
            Votre point de départ : la carte !
          </Typography>
          <Typography color={"primary"} paragraph={true}>
            Explorez, cliquez sur les spots pour voir les conversations, et venez participer.
          </Typography>
          <Typography color={"primary"}  paragraph={true}>
            Ajoutez des spots dans vos favoris pour être prévenus des nouvelles conversations et les suivre dans votre fil d'actualité.
          </Typography>
          <Typography color={"primary"}  paragraph={true}>
            Cliquez sur les autres membres pour discuter directement avec eux.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {PreviousButton()}
        {NextButton()}
      </DialogActions>
    </Dialog>
  );
}
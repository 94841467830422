import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

export default function TutorialIntro2() {

  const navigate = useNavigate()

  function PreviousButton() {
    return (
      <Button
        onClick={() => navigate ("/TutorialIntro1", {replace : true})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateBeforeIcon />
      </Button>
    );
  }

  function NextButton() {
    return (
      <Button
      onClick={() => navigate ("/TutorialIntro3", {replace : true})}
      variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateNextIcon />
      </Button>
    );
  }

  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
          <Typography variant = "h6" color={"primary"} paragraph={true}>
            Au mouillage, en navigation, discutez enfin avec les autres bateaux !
          </Typography>
          <Typography color={"primary"} paragraph={true}>
          - Vous savez si les calanques sont abritées du vent cette nuit ?
          </Typography>
          <Typography color={"primary"} paragraph={true}>
          - Je prévois de venir mouiller ce soir, il n'y a pas trop de houle ?
          </Typography>
          <Typography color={"primary"} paragraph={true}>
          - Qui aurait un tire-bouchon à me prêter ? Je viens le chercher en annexe !
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {PreviousButton()}
        {NextButton()}
      </DialogActions>
    </Dialog>
  );
}